import React, { useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"

import AppLayout from "../components/AppLayout"
import SEO from "../components/SEO"
import { JungContext } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"
import InitialiseAudio from "../components/InitialiseAudio"
import UnsupportedBrowser from "../components/UnsupportedBrowser"
import { useJungAudioSupport } from "../../plugins/gatsby-plugin-jung-wrapper/useJungAudioSupport"
import StreamSwitcher from "../components/StreamSwitcher"

import "./streams.scss"

const StreamsPage = (location) => {
    const audioSupport = useJungAudioSupport()
    const jungContext = useContext(JungContext)
    const [stateCnt, setStateCnt] = useState(0)

    useEffect(() => {
        const unSubState = jungContext.onStateChange(() => setStateCnt((c) => c + 1))
        const unSubAudio = jungContext.onAudioStateChange(() => setStateCnt((c) => c + 1))
        const unSubFullscreen = jungContext.onFullScreenChange(() => setStateCnt((c) => c + 1))
        return () => {
            unSubState()
            unSubAudio()
            unSubFullscreen()
        }
    }, [jungContext])

    return (
        <AppLayout location={location} jungState="orbFullView">
            <SEO title="Streams" description="" />
            <Helmet
                bodyAttributes={{
                    class: "app streams",
                }}
                key="helmet"
            />

            {!jungContext.isFullScreen && (
                <button className="buttonFullscreen" onClick={() => jungContext.setFullScreen(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M32,1c0-0.558-0.442-1-1-1l-8.985,0c-0.568,0-0.991,0.448-0.992,1.016C21.023,1.583,21.447,2,22.015,2L30,2   l-0.016,8.023c0,0.568,0.432,1,1,1c0.568-0.001,1-0.432,1-1L32,1.015c0-0.003-0.001-0.005-0.001-0.007C31.999,1.005,32,1.003,32,1z   " />
                        <path d="M10.016,0H1.031C1.028,0,1.026,0.001,1.023,0.001C1.021,0.001,1.018,0,1.016,0c-0.558,0-1,0.442-1,1   L0,10.008C0,10.576,0.448,11,1.016,11C1.583,11,2,10.576,2,10.008L2.016,2h8c0.568,0,1-0.432,1-1C11.015,0.432,10.583,0,10.016,0z" />
                        <path d="M9.985,30H2v-8c0-0.568-0.432-1-1-1c-0.568,0-1,0.432-1,1v8.985c0,0.003,0.001,0.005,0.001,0.007   C0.001,30.995,0,30.997,0,31c0,0.558,0.442,1,1,1h8.985c0.568,0,0.991-0.448,0.992-1.016C10.977,30.417,10.553,30,9.985,30z" />
                        <path d="M30.984,21.023c-0.568,0-0.985,0.424-0.984,0.992V30l-8,0c-0.568,0-1,0.432-1,1c0,0.568,0.432,1,1,1   l8.985,0c0.003,0,0.005-0.001,0.007-0.001C30.995,31.998,30.997,32,31,32c0.558,0,1-0.442,1-1v-8.985   C32,21.447,31.552,21.023,30.984,21.023z" />
                    </svg>
                </button>
            )}

            {jungContext.isAudioOn() && (
                <div className="streamIntroText">Press to find what music resonates with you today.</div>
            )}

            <StreamSwitcher />

            {jungContext.session && !jungContext.isAudioOn() && audioSupport === "supported" && <InitialiseAudio />}

            {audioSupport === "unsupported" && <UnsupportedBrowser location={location.location} />}
        </AppLayout>
    )
}

export default StreamsPage
