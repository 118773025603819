import React, { useContext, useEffect, useState, useCallback } from "react"
import { findIndex } from "lodash"
import { Tunnel } from "react-tunnels"
import { throttle } from "lodash"

import { JungContext } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"
import * as freudApi from "../util/freudApiUtil"
import * as audioUtils from "../util/audioUtils"
import { useAnonymousToken } from "../hooks"

import "./StreamSwitcher.scss"

const StreamSwitcher = () => {
    let jungContext = useContext(JungContext)
    let [streams, setStreams] = useState([])
    const { anonymousToken } = useAnonymousToken()

    let onSwitchStream = useCallback(
        throttle(() => {
            if (streams.length === 0) return

            let currentBroadcastId = jungContext.session
            let currentStreamIndex = findIndex(streams, (s) => s.broadcastIdentifier === currentBroadcastId)
            let nextStreamIndex = (currentStreamIndex + 1) % streams.length
            let nextStream = streams[nextStreamIndex]

            jungContext.setSession(nextStream.broadcastIdentifier, {
                anonymousToken,
                forcingAutoPlay: true,
                isInfinite: true,
            })
            setPreferredStreamId(nextStream.broadcastIdentifier)
            audioUtils.play()
        }, 2000),
        [jungContext, anonymousToken, streams]
    )

    useEffect(
        function loadStreams() {
            if (!anonymousToken) return
            freudApi.listStreams(anonymousToken).then(setStreams)
            audioUtils.initialise()
        },
        [jungContext, anonymousToken]
    )

    return (
        <>
            {/* DO NOT DELETE DUPLICATE: added to prevent z-index stacking conflict with enable audio button in content layer */}
            <button className="streamSwitcher" onClick={onSwitchStream}></button>
            <Tunnel id="jungButtonTunnel">
                <button className="streamSwitcher" onClick={onSwitchStream}></button>
            </Tunnel>
        </>
    )
}

function setPreferredStreamId(stream) {
    localStorage.setItem("preferredStreamId", stream)
}

export default StreamSwitcher
