import { sample } from "lodash"
import { getAudioContext } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"

import bridgeAudio1 from "../audio/ui1.mp3"
import bridgeAudio2 from "../audio/ui2.mp3"
import bridgeAudio3 from "../audio/ui3.mp3"
import bridgeAudio4 from "../audio/ui4.mp3"
import bridgeAudio5 from "../audio/ui5.mp3"
import bridgeAudio6 from "../audio/ui6.mp3"
import bridgeAudio7 from "../audio/ui7.mp3"

let audioURLs = [bridgeAudio1, bridgeAudio2, bridgeAudio3, bridgeAudio4, bridgeAudio5, bridgeAudio6, bridgeAudio7]

let audioArrayBuffers

export async function initialise() {
    audioArrayBuffers = await Promise.all(
        audioURLs.map((audioURL) => fetch(audioURL).then((response) => response.arrayBuffer()))
    )
}

export async function play() {
    let audioSampleBuffer = sample(audioArrayBuffers)
    if (!audioSampleBuffer) {
        console.error("Audio not initialised")
        return
    }
    let audioCtx = getAudioContext()
    let rawAudioBuffer = await new Promise((res, rej) => audioCtx.decodeAudioData(audioSampleBuffer.slice(0), res, rej))
    let audioSourceNode = audioCtx.createBufferSource()
    audioSourceNode.buffer = rawAudioBuffer
    let gainNode = audioCtx.createGain()
    gainNode.gain.value = 0.5
    audioSourceNode.connect(gainNode)
    gainNode.connect(audioCtx.destination)
    audioSourceNode.start(audioCtx.currentTime)
}
